
import { 
  IonPage, 
  IonContent, 
  IonCol, 
  IonGrid, 
  IonRow, 
  IonLoading, 
  IonRefresher, 
  IonRefresherContent,
  IonSlide,
  IonSlides,
  IonCard
} from '@ionic/vue';

import Header from '@/components/Header.vue';

// @ts-ignore
import filterHelperMethods from '@/helper/filter'
// @ts-ignore
import viewHelperMethods from '@/helper/view'

// @ts-ignore
import * as api from '@/service/index.js'

export default  {
  name: 'ArticleDetail',
  components: { 
    Header, 
    IonContent, 
    IonPage, 
    IonCol, 
    IonGrid, 
    IonRow,
    IonLoading, 
    IonRefresher, 
    IonRefresherContent,
    IonSlide,
    IonSlides,
    IonCard
  },
  data () {
    return {
      isLoading: false,
      entry: {},
      firstLoadDone: false,
      slideOptions: {
        slidesPerView: 1.5,
        centeredSlides: true,
        loop: true,
        spaceBetween: 5,
        speed: 400,
        delay: 3000,
        autoplay: true
        // disableOnInteraction: false
      }
    }
  },
  computed: {
    showNoEntry (): boolean {
      return (this as any).firstLoadDone && !(this as any).entry.id 
    },
    showArticle (): boolean {
      return (this as any).firstLoadDone && (this as any).entry.id 
    },
    showSlider (): boolean {
      return (this as any).showArticle && (this as any).entry.sliderContent.length
    }
  },
  methods: {
    ...filterHelperMethods,
    ...viewHelperMethods,
    downloadPdf (file: string) {
        const fetchOpts = {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('jwt')}`
          }
        };
        fetch(file, fetchOpts)
          .then( res => res.blob() )
          .then( blob => {
            let fileUrl = window.URL.createObjectURL(blob);
            let a = document.createElement("a");
            document.body.appendChild(a);
            a.href = fileUrl;
            a.download = `UKK-Aktuell-${Date.now()}.pdf`;
            a.click();
          });
    },
    goHome () {
      (this as any).$router.push({ path: '/news' });
    },
    reset () {
      (this as any).isLoading = false;
      (this as any).entry = {}; 
    },
    setLoading (val: boolean) {
      console.log('set loading %s', val);
      (this as any).isLoading = val;
    },
    async loadPage (id: number, event: any) {
      console.log('start load');
      const vm = (this as any);
      vm.setLoading(true);

      try {
        const data = await api.getContentElementById(id);
        console.log(data);

        // vm.entry = data.data
        if (data.data.id) {
          vm.entry = data.data
        }
      } catch (error) {
        vm.handleAxiosError(error);
        console.log(error);
      }

      console.log('end load');
      setTimeout(function() {
        vm.setLoading(false);
      }, 500);

      vm.firstLoadDone = true;

      if (event?.type === 'ionrefresh') {
        event.target.complete();
      }
    },
    async doRefresh (event: any) {
      (this as any).reset();
      (this as any).loadPage((this as any).$route.params.id, event);
    },
    handleAxiosError (err: any) {
      if (err && err.message) {
        if (err.message.indexOf('401') !== -1) {
          console.log('unauthorized');
          localStorage.removeItem('jwt');
          localStorage.removeItem('jwt_expires');
          (this as any).$router.push('/auth');
        }
      }
    }
  },
  ionViewWillLeave () {
    console.log('[ionView] will leave page detail');
  },
  ionViewDidEnter () {
    console.log('[ionView] entered page detail');
  },
  mounted () {
    console.log('Loading page ', (this as any).$route.params.id);
    (this as any).reset();
    (this as any).loadPage((this as any).$route.params.id);
  },
  watch: {
    $route() {
      // react to route changes...
      if ((this as any).$route.params.id) {
        console.log('View updated, loading page ', (this as any).$route.params.id);
        (this as any).reset();
        (this as any).loadPage((this as any).$route.params.id);
      }
    }
  }
}
