<template>
  <ion-page>
    <!-- <Header :name="entry.header || ''" /> -->
    <Header name="UKK Aktuell" />
    <ion-content :fullscreen="true">
      <ion-refresher slot="fixed" @ionRefresh="doRefresh($event)">
        <ion-refresher-content></ion-refresher-content>
      </ion-refresher>

      <div id="container">

        <ion-loading
          :is-open="isLoading"
          message="Lade Daten.."
          @onDidDismiss="setLoading(false)"
          :duration="3000"
        >
        </ion-loading>

        <div class="go-back" @click="goHome()">
          <span class="font-face--brand">Zur Startseite</span>
        </div>

        <div 
          class="blog-header"
          :class="{'zero-height': !hasImage(entry)}" 
          v-if="!showNoEntry"
        >
          <div v-if="hasImage(entry)" class="details-image">
            <img :src="getImageUrl(entry.image)" width="600" height="450" />
          </div>
        </div>

        <ion-grid style="padding: 0">

          <ion-row v-if="showNoEntry" class="no-data">
            <ion-col>
              <div class="card">
                <h3>Fehler beim Laden des Artikels</h3>
              </div>
            </ion-col>
          </ion-row>

          <div v-if="showArticle">
            <ion-row>
              <ion-col style="padding: 0">
                <div class="card news-entry">
                  <div class="content">
                    <div class="text">
                      <h1 class="main-title font-face--merrisans light">{{ entry.header }}</h1>
                      <p class="font-face--merrisans light" v-html="getHTML(entry.content)"></p>
                      <a 
                        v-if="entry.pdf?.length"
                        @click.prevent="downloadPdf(`https://api.uk-aktuell.sunzity.de${entry.pdf}`)" 
                        href="#"
                        role="button" 
                        class="btn btn-next btn-secondary"
                      >
                          <span>PDF herunterladen</span>
                      </a>
                    </div>
                  </div>
                  <div v-if="showSlider" class="slider">
                    <ion-slides pager="true" :options="slideOptions">
                      <ion-slide 
                        v-for="(item, i) in entry.sliderContent" 
                        :key="i"
                      >
                        <ion-card><img :src="item.url"></ion-card>
                      </ion-slide>
                    </ion-slides>
                  </div>
                </div>
              </ion-col>
            </ion-row>

          </div>

        </ion-grid>
      </div>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { 
  IonPage, 
  IonContent, 
  IonCol, 
  IonGrid, 
  IonRow, 
  IonLoading, 
  IonRefresher, 
  IonRefresherContent,
  IonSlide,
  IonSlides,
  IonCard
} from '@ionic/vue';

import Header from '@/components/Header.vue';

// @ts-ignore
import filterHelperMethods from '@/helper/filter'
// @ts-ignore
import viewHelperMethods from '@/helper/view'

// @ts-ignore
import * as api from '@/service/index.js'

export default  {
  name: 'ArticleDetail',
  components: { 
    Header, 
    IonContent, 
    IonPage, 
    IonCol, 
    IonGrid, 
    IonRow,
    IonLoading, 
    IonRefresher, 
    IonRefresherContent,
    IonSlide,
    IonSlides,
    IonCard
  },
  data () {
    return {
      isLoading: false,
      entry: {},
      firstLoadDone: false,
      slideOptions: {
        slidesPerView: 1.5,
        centeredSlides: true,
        loop: true,
        spaceBetween: 5,
        speed: 400,
        delay: 3000,
        autoplay: true
        // disableOnInteraction: false
      }
    }
  },
  computed: {
    showNoEntry (): boolean {
      return (this as any).firstLoadDone && !(this as any).entry.id 
    },
    showArticle (): boolean {
      return (this as any).firstLoadDone && (this as any).entry.id 
    },
    showSlider (): boolean {
      return (this as any).showArticle && (this as any).entry.sliderContent.length
    }
  },
  methods: {
    ...filterHelperMethods,
    ...viewHelperMethods,
    downloadPdf (file: string) {
        const fetchOpts = {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('jwt')}`
          }
        };
        fetch(file, fetchOpts)
          .then( res => res.blob() )
          .then( blob => {
            let fileUrl = window.URL.createObjectURL(blob);
            let a = document.createElement("a");
            document.body.appendChild(a);
            a.href = fileUrl;
            a.download = `UKK-Aktuell-${Date.now()}.pdf`;
            a.click();
          });
    },
    goHome () {
      (this as any).$router.push({ path: '/news' });
    },
    reset () {
      (this as any).isLoading = false;
      (this as any).entry = {}; 
    },
    setLoading (val: boolean) {
      console.log('set loading %s', val);
      (this as any).isLoading = val;
    },
    async loadPage (id: number, event: any) {
      console.log('start load');
      const vm = (this as any);
      vm.setLoading(true);

      try {
        const data = await api.getContentElementById(id);
        console.log(data);

        // vm.entry = data.data
        if (data.data.id) {
          vm.entry = data.data
        }
      } catch (error) {
        vm.handleAxiosError(error);
        console.log(error);
      }

      console.log('end load');
      setTimeout(function() {
        vm.setLoading(false);
      }, 500);

      vm.firstLoadDone = true;

      if (event?.type === 'ionrefresh') {
        event.target.complete();
      }
    },
    async doRefresh (event: any) {
      (this as any).reset();
      (this as any).loadPage((this as any).$route.params.id, event);
    },
    handleAxiosError (err: any) {
      if (err && err.message) {
        if (err.message.indexOf('401') !== -1) {
          console.log('unauthorized');
          localStorage.removeItem('jwt');
          localStorage.removeItem('jwt_expires');
          (this as any).$router.push('/auth');
        }
      }
    }
  },
  ionViewWillLeave () {
    console.log('[ionView] will leave page detail');
  },
  ionViewDidEnter () {
    console.log('[ionView] entered page detail');
  },
  mounted () {
    console.log('Loading page ', (this as any).$route.params.id);
    (this as any).reset();
    (this as any).loadPage((this as any).$route.params.id);
  },
  watch: {
    $route() {
      // react to route changes...
      if ((this as any).$route.params.id) {
        console.log('View updated, loading page ', (this as any).$route.params.id);
        (this as any).reset();
        (this as any).loadPage((this as any).$route.params.id);
      }
    }
  }
}
</script>

<style scoped lang="less">
.load-more {
  margin-top: 15px;
}

.go-back {
  height: 50px;
  width: 100%;
  padding: 0 15px;
  background: #fff;
  color: var(--text-color-blue);
  font-size: 20px;

  span {
    line-height: 54px;
    position: relative;
    margin-left: 27px;

    &:before {
      left: -32px;
      bottom: 5.5px;
      position: absolute;
      width: 22px;
      height: 22px;
      background-image: url('~@/assets/img/icons/arrow-next-blue.svg');
      transform: rotate(180deg);
      background-repeat: no-repeat;
      content: "";
    }
  }
}

h1.main-title {
  font-size: 28px;
  line-height: 37.7px;
  margin-top: 0;
}

p {
  font-size: 15px;
  line-height: 28.5px;
}

.blog-header {
  min-height: 130px;
  width: 100%;
  padding: 0;
  margin-bottom: -3px;

  img {
    width: 100%;
    height: auto;
  }

  h1 {
    color: #fff;
    font-weight: 400;
    font-size: 22px;
    line-height: 30px;
  }
}

.zero-height {
  min-height: 0 !important;
  height: 0 !important;
}

.btn-secondary {
    margin-top: 15px;
}


@media (prefers-color-scheme: dark) {
  .card {
    background: var(--background-black);

    h1,h3 {
      color: #fff;
    }

    p {
      color: #fff;
    }
  }

  .go-back {
    background: #000;
    color: var(--text-color-blue);

    span {
      &:before {
        background-image: url('~@/assets/img/icons/arrow-next-lightblue.svg');
      }
    }
  }

  .btn-secondary {
    color: #fff !important;
    background: var(--text-color-blue) !important;

    &.btn-next span::after {
        background-image: url('~@/assets/img/icons/arrow-next.svg');
    }
  }
}
body.dark {
  .card {
    background: var(--background-black);

    h1,h3 {
      color: #fff;
    }

    p {
      color: #fff;
    }
  }

  .go-back {
    background: #000;
    color: var(--text-color-blue);

    span {
      &:before {
        background-image: url('~@/assets/img/icons/arrow-next-lightblue.svg');
      }
    }
  }

  .btn-secondary {
    color: #fff !important;
    background: var(--text-color-blue) !important;

    &.btn-next span::after {
        background-image: url('~@/assets/img/icons/arrow-next.svg');
    }
  }
}

.slider {
  ion-card {
    width: 100%;
    min-height: 160px;
    box-shadow: none;

    img {
      object-fit: cover;
      width: 100%;
      transform: scale(1.1, 1.1);
      transition: 400ms all ease-in-out;
    }
  }

  .swiper-slide {
    transition: 400ms all ease-in-out;
  }

  .swiper-slide-active {
    ion-card img {
      transform: scale(1, 1);
    }
  }
}
</style>

<style>
  .swiper-pagination-bullet-active {
    background: var(--text-color-blue) !important;
  }
</style>